import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration1 from '../components/Decoration/Decoration-1';
import VideoYoutube from '../components/VideoYoutube';

import imageThirdSection from '../assets/images/photos/quantum/cta.png';
import SvgGooglePlayBadge from '../assets/images/vectors/badges/google-play-badge.inline.svg';
import SvgAppStoreBadge from '../assets/images/vectors/badges/app-store-badge.inline.svg';

import '../assets/styles/pages/quantum-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const QuantumPage = ({ data }) => {
  const prismicData = data.prismic.allQuantum_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Quantum" />
      <div className="quantum-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
        <ThirdSection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({
  data: { imageFirstSection },
  prismicData: { first_section_title, first_section_subtitle },
}) => (
  <section className="common-first-section">
    <div className="container">
      <div className="cfs__wrapper">
        <div className="cfs__l">
          <div>
            <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>
            <p>{RichText.asText(first_section_subtitle)}</p>
            <StartJourneyButton text={'Start your journey'} />
          </div>
        </div>
        <div className="cfs__r">
          <Decoration1 />
          <Img fluid={imageFirstSection.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({ prismicData }) => (
  <section className="c-section-mt second-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="second-section__image">
            <VideoYoutube
              url={prismicData.video_link.url}
              imagePreview={prismicData.video_preview.url}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ThirdSection = ({ prismicData: { third_section_title } }) => (
  <section className="third-section">
    <div className="third-section__bg">
      <div className="container">
        <div className="row">
          <div className="col-md-6 third-section__text">
            <div className="c-h1">{RichText.asText(third_section_title)}</div>
            <div className="third-section__text__badges">
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <SvgGooglePlayBadge />
              </a>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <SvgAppStoreBadge />
              </a>
            </div>
          </div>
          <div className="col-md-6 third-section__image">
            <img src={imageThirdSection} className="w-100" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allQuantum_pages {
        edges {
          node {
            first_section_title
            first_section_subtitle
            video_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            video_preview
            third_section_title
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/quantum/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default QuantumPage;
